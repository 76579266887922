export const API_URL = process.env.NEXT_PUBLIC_API_URL;
export const PHONE_NUMBER = "+18084355122";

export const DATE = "1st Jan 2025";
export const TRIAL_DURATION = "3 days";
export const JURISDICTION = "Delaware";
export const DISPUTE_RESOLUTION_PROCESS = "";
export const NOTIFICATION_METHOD = "phone";
export const RETENTION_PERIOD = "90 days";
export const AGE = "18";
export const CONTACT_METHOD = "team@callmandrake.com";
export const CONTACT_EMAIL = "team@callmandrake.com";
export const CONTACT_PHONE_NUMBER = "";
