"use client";

import { UserStatuses } from "@/lib/types";
import Onboarding from "@/page-components/Onboarding";
import Start from "@/page-components/Start";
// import Waitlist from "@/page-components/Waitlist";
import userStore from "@/store";
import { useRouter } from "next/navigation";

export default function Home() {
  const { userStatus, isOnboarded, setIsOnboarded } = userStore();
  const router = useRouter();

  if (
    isOnboarded &&
    (userStatus === UserStatuses.ACTIVE ||
      userStatus === UserStatuses.ONBOARDED)
  ) {
    router.push("/credits");
    setIsOnboarded(false);
    return null;
  }

  // remove also userStatus === UserStatuses.ONBOARDED if needed ref page
  if (
    userStatus === UserStatuses.ACTIVE ||
    userStatus === UserStatuses.ONBOARDED
  ) {
    router.push("/notes");
    return null;
  }

  return (
    <>
      {userStatus === UserStatuses.NEW ? (
        <Onboarding />
      ) : // : userStatus === UserStatuses.ONBOARDED ? (
      //   <Waitlist />
      // )
      userStatus === UserStatuses.START ? (
        <Start />
      ) : userStatus === UserStatuses.EXPIRED ? (
        <Start />
      ) : (
        <Start />
      )}
    </>
  );
}
