import { SubscriptionStatusEnum } from "@/lib/types";
import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

const days = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
const shortMonths = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function formatDateToShort(dateString: string) {
  const date = new Date(dateString);

  const dayOfWeek = days[date.getDay()];
  const day = date.getDate();
  const month = shortMonths[date.getMonth()];
  const year = date.getFullYear();

  return `${dayOfWeek}, ${day} ${month} ${year}`;
}

export function formatDateToLong(dateString: string) {
  const date = new Date(dateString);

  const day = daysOfWeek[date.getDay()];
  const dayOfMonth = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  const isPM = hours >= 12;
  const formattedHours = hours % 12 || 12;
  const formattedMinutes = minutes.toString().padStart(2, "0");
  const ampm = isPM ? "pm" : "am";

  return `${day}, ${dayOfMonth} ${month} ${year} at ${formattedHours}:${formattedMinutes}${ampm}`;
}

export function formatNumberWithCommas(num: number) {
  return num?.toLocaleString("en-US") || "0";
}

export function getCurrentPlanTitle(
  status: SubscriptionStatusEnum,
  dateString: string
) {
  const date = new Date(dateString || "").toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
  });

  switch (status) {
    case SubscriptionStatusEnum.INACTIVE:
      return `Plan is inactive since ${date}`;
    case SubscriptionStatusEnum.ACTIVE:
      return `Renews on ${date}`;
    case SubscriptionStatusEnum.TRIAL:
      return `Trial ends on ${date}`;
    case SubscriptionStatusEnum.CANCELLED:
      return `Expires in ${date}`;
    case SubscriptionStatusEnum.PAST_DUE:
      return `Payment past due since ${date}`;
    default:
      return "";
  }
}

export function formatError(message: string) {
  return message
    .replace(/^\w/, (c) => c.toUpperCase())
    .replace(/(?<!\.)$/, ".");
}

export function getFieldErrorByFieldName(fieldName: string) {
  switch (fieldName) {
    case "name":
      return "Enter your name. This will be used to address you correctly.";
    case "birthday":
      return "Enter your birthday. Used to customise responses made to you.";
  }
}

export function formatCredits(credits: number, type: 'sms' | 'minutes') {
  if (type === 'sms') {
    return credits;
  }
  
  if (type === 'minutes') {
    return Math.ceil(credits / 5);
  }

  return 0
}